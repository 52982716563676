import React from "react";
import {
  getLoggedInUserAsObservable,
  getTenantAsObservable,
} from "./user-tenant-data.js";
import { handleError } from "src/handle-error.helper";
import Cancelable from "react-disposable-decorator";
import { hasAccess } from "./permission.helpers.js";
import { getBetasAsObservable } from "./betas.helpers";

const defaultOpts = {
  // Don't render the component until the user and tenant and rendered. On by default
  waitForData: true,
  permissions: {},
  betas: null,
};

const userPermissions = (user, permissions) => {
  return Object.keys(permissions || {}).reduce((acc, key) => {
    acc[key] = hasAccess(user)(permissions[key]);
    return acc;
  }, {});
};

const userBetas = (userBetas, betasFromOptions) => {
  const checkBeta = (betasOnKey) => {
    if (typeof betasOnKey === "string") {
      return userBetas?.some((userBeta) => betasOnKey === userBeta);
    }
    return betasOnKey?.every((beta) => {
      return userBetas?.some((userBeta) => beta === userBeta);
    });
  };

  return Object.keys(betasFromOptions || {}).reduce((acc, key) => {
    acc[key] = checkBeta(betasFromOptions[key]);
    return acc;
  }, {});
};

export default function (opts = defaultOpts) {
  return (DecoratedComponent) => {
    class UserTenantProps extends React.Component {
      state = {
        loggedInUser: window.loggedInUser,
        tenant: window.tenant,
        permissions: userPermissions(window.loggedInUser, opts.permissions),
        betas: window.betas && userBetas(window.betas, opts.betas),
      };
      componentDidMount() {
        this.props.cancelWhenUnmounted(
          getLoggedInUserAsObservable().subscribe(
            (loggedInUser) =>
              this.setState({
                loggedInUser,
                permissions: userPermissions(loggedInUser, opts.permissions),
              }),
            handleError
          ),

          getTenantAsObservable().subscribe(
            (tenant) => this.setState({ tenant }),
            handleError
          )
        );
        if (opts.betas) {
          this.props.cancelWhenUnmounted(
            getBetasAsObservable().subscribe((betas) => {
              this.setState({ betas: userBetas(betas, opts.betas) });
            }, handleError)
          );
        }
      }
      render() {
        const hasData =
          this.state.loggedInUser &&
          this.state.tenant &&
          (!opts.betas || !!this.state.betas);
        const { loggedInUser, tenant, permissions, betas } = this.state;
        if (opts.waitForData && !hasData) {
          return null;
        } else {
          return (
            <DecoratedComponent
              {...this.props}
              {...{ loggedInUser, tenant, permissions, betas }}
            />
          );
        }
      }
    }

    return Cancelable(UserTenantProps);
  };
}
