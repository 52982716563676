import { useState, useEffect } from "react";
import { getBetasAsObservable } from "./betas.helpers";
import { handleError } from "src/handle-error.helper";

export function useBetas(betas = [], requireAll) {
  if (!betas || !betas.length) {
    console.warn("No betas were passed to `useBetas`");
  }
  const [access, setAccess] = useState();
  useEffect(() => {
    const subscription = getBetasAsObservable().subscribe((resp) => {
      const checkBeta = (beta) => {
        return resp?.some((tenantBeta) => beta === tenantBeta);
      };
      if (typeof betas === "string") {
        return setAccess(checkBeta(betas));
      }
      const hasAccess = requireAll
        ? betas.every(checkBeta)
        : betas.some(checkBeta);
      setAccess(hasAccess);
    }, handleError);
    return () => subscription.unsubscribe();
  }, [`${betas}`, requireAll]);

  return access;
}
