export function isClientLimitModel(clientLimit) {
  return !!clientLimit;
}

export function isFreeClientLimitModelTier(clientLimit) {
  return isClientLimitModel(clientLimit) && !window.tenant.is_active;
}

export function isPaidClientLimitModelTier(clientLimit) {
  return isClientLimitModel(clientLimit) && window.tenant.is_active;
}
