import React from "react";
import PropTypes from "prop-types";
import styles from "./practice-management-freemium-welcome-back-modal.styles.css";
import { CpButton, constants } from "canopy-styleguide!sofe";

PracticeManagementFreemiumWelcomeBackModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default function PracticeManagementFreemiumWelcomeBackModal(props) {
  return (
    <div className="cps-modal">
      <div className="cps-modal__screen"></div>
      <div className={`${styles.dialog} cps-modal__dialog cps-card__height-3`}>
        <div className={styles.header}>
          <CpButton icon="close-large" aria-label="Close" onClick={close} />
        </div>
        <div className={`${styles.body} cps-card__body cps-body`}>
          <img
            className={styles.icon}
            src="https://cdn.canopytax.com/static/empty-states/es_clients.svg"
          />
          <div className="cps-subheader">Welcome Back!</div>
          <div className={`${styles.updatedText} cps-wt-semibold`}>
            Your subscription details have changed since you last logged in.
          </div>
          <div>
            <span>
              You now have access to our Client Management for up to 500 active
              clients.
            </span>
          </div>
          <div className={styles.upgradeText}>
            <span>
              To regain access to additional Canopy modules or add more clients,
              chat us in-app or call{" "}
              <span className="cps-wt-semibold">{constants.canopyPhone}</span>{" "}
              today.
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  function close() {
    sessionStorage.setItem(
      "hasClosedPracticeManagementFreemiumWelcomeBackModal",
      true
    );
    props.close();
  }
}
