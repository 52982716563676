import { useState, useEffect } from "react";
import { handleError } from "src/handle-error.helper";
import {
  getLoggedInUserAsObservable,
  getTenantAsObservable,
} from "./user-tenant-data.js";
import { isEqual } from "lodash";

export default function UseWithUserAndTenant() {
  const [user, setUser] = useState(window.loggedInUser);
  const [tenant, setTenant] = useState(window.tenant);

  useEffect(() => {
    const tenant$ = getTenantAsObservable().subscribe((resp) => {
      if (!isEqual(resp, tenant)) {
        setTenant(resp);
      }
    }, handleError);
    const user$ = getLoggedInUserAsObservable().subscribe((resp) => {
      if (!isEqual(resp, user)) {
        setUser(resp);
      }
    }, handleError);
    return () => {
      tenant$.unsubscribe();
      user$.unsubscribe();
    };
  }, []);
  return [user, tenant];
}
