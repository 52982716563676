import { isPaidClientLimitModelTier } from "./client-limit-model.helper";

export function hasLicense(type, licenses, clientLimit, hasFreeTranscripts) {
  return (
    isFreeLicense(type, clientLimit, hasFreeTranscripts) ||
    hasPurchasedLicense(type, licenses, clientLimit, hasFreeTranscripts)
  );
}

// Only use this method when absolutely necessary e.g. when using a permission does not make sense.
// Using permissions is the preferred way to restrict/allow features
export function userHasOnlyLicense(user, type) {
  if (!type || !user || user.licenses?.length !== 1) {
    return false;
  }
  return user.licenses?.[0] === type;
}

export function isFreeLicense(type, clientLimit, hasFreeTranscripts) {
  return (
    isClientLimitPracticeManagementLicense(type, clientLimit) ||
    isFreeTranscriptsLicense(type, hasFreeTranscripts)
  );
}

export function hasPurchasedLicense(
  type,
  licenses,
  clientLimit,
  hasFreeTranscripts
) {
  if (isFreeLicense(type, clientLimit, hasFreeTranscripts)) {
    if (isClientLimitPracticeManagementLicense(type, clientLimit)) {
      return isPaidClientLimitModelTier(clientLimit);
    }

    return false;
  }

  return hasPurchasedTotal(type, licenses);
}

export function isWithinLicenseLimit(
  type,
  licenses,
  clientLimit,
  hasFreeTranscripts
) {
  return (
    getAvailableLicenseCount(type, licenses, clientLimit, hasFreeTranscripts) >=
    0
  );
}

export function getAvailableLicenseCount(
  type,
  licenses,
  clientLimit,
  hasFreeTranscripts
) {
  if (
    isFreeLicense(type, clientLimit, hasFreeTranscripts) ||
    (typeof licenses[type]?.totalPurchased === "number" &&
      licenses[type].totalPurchased === -1)
  ) {
    return Infinity;
  } else if (licenses) {
    if (
      licenses[type] &&
      typeof licenses[type].totalPurchased === "number" &&
      typeof licenses[type].totalAssigned === "number"
    ) {
      return licenses[type].totalPurchased - licenses[type].totalAssigned;
    } else {
      return 0;
    }
  }

  return -1;
}

export function isClientLimitPracticeManagementLicense(type, clientLimit) {
  return (
    (type === "practiceManagement" || type === "clientManagement") &&
    !!clientLimit
  );
}

function hasPurchasedTotal(type, licenses) {
  return !!licenses && !!licenses[type] && !!licenses[type].totalPurchased;
}

function isFreeTranscriptsLicense(type, hasFreeTranscripts) {
  return type === "transcripts" && hasFreeTranscripts;
}
