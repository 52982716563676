import { isEmpty } from "lodash";
import { getLoggedInUser } from "./user-tenant-data";

// requireAll: if true, the function checks if the user has ALL of the given permissions. If false, checks if user has ANY of the permissions.
export function hasAccess(user, _requireAll = false) {
  return function (permissions = [], __requireAll = false) {
    if (isEmpty(user)) {
      return false;
    }

    const requireAll = _requireAll || __requireAll;
    if (!(permissions instanceof Array)) permissions = [permissions];
    permissions.forEach((p) => {
      if (Object.keys(deprecatedPermissions).includes(p)) {
        const newPermission = deprecatedPermissions[p];
        console.warn(
          `The permission "${p}" has been deprecated. ${
            newPermission ? `Please use ${newPermission} instead.` : ""
          }`
        );
      }
    });
    permissions = permissions.map(toSnake);
    return requireAll
      ? permissions.every((permission) => user.permissions[permission])
      : permissions.some((permission) => user.permissions[permission]);
  };
}

export function getUserHasAccess(permissions, requireAll = false) {
  const user = getLoggedInUser();
  return hasAccess(user, requireAll)(permissions);
}

function toSnake(str) {
  return str
    .trim()
    .replace(/([a-z\d])([A-Z]+)/g, "$1_$2")
    .replace(/[-\s]+/g, "_")
    .toLowerCase();
}

const deprecatedPermissions = {
  client_requests: "tasks_client_requests",
  billing: "one of the new billing permissions",
  payments: "one of the new payments permissions",
  tasks: "one of the new tasks permissions",
  client_request_templates: "templates_client_requests",
  team_member: "",
  client: "",
  dates: "",
  notifications: "",
};
