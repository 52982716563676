// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal-agreements-modal-styles__header--nSLqm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.legal-agreements-modal-styles__body--EIOCm {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.legal-agreements-modal-styles__policyWrapper--x7pYZ {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.legal-agreements-modal-styles__modal--_Mbtd {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 245, 248);
}

.legal-agreements-modal-styles__card--XLK4B {
  max-width: calc(100% - 16px);
  width: 620px;
  padding: 34px 24px;
  top: 5%;
  height: calc(100vh - 10%);
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  border-radius: 1px;
  border: 1px solid rgb(233, 233, 233);
  box-shadow: 0px 10px 30px -24px rgba(75, 78, 83, 0.4);
}

.legal-agreements-modal-styles__footer--Ek7tS {
  margin-top: 24px;
  margin-bottom: 8px;
}

.legal-agreements-modal-styles__buttons--cipDM {
  display: flex;
}

.legal-agreements-modal-styles__agreementTerm--qVpNX {
  border: 1px solid var(--cps-color-gray4);
  border-radius: 5px;
  overflow-y: auto;
  flex: 1;
  padding: 0px 16px;
  min-height: 0;
}

.legal-agreements-modal-styles__agreementTerm--qVpNX h2 {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/legal-agreements/legal-agreements-modal.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,OAAO;EACP,aAAa;AACf;;AAEA;EACE,OAAO;EACP,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,eAAe;EACf,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,kBAAkB;EAClB,OAAO;EACP,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,oCAAoC;EACpC,qDAAqD;AACvD;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wCAAwC;EACxC,kBAAkB;EAClB,gBAAgB;EAChB,OAAO;EACP,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".header {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.body {\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  min-height: 0;\n}\n\n.policyWrapper {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.modal {\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 100000;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  background-color: rgb(244, 245, 248);\n}\n\n.card {\n  max-width: calc(100% - 16px);\n  width: 620px;\n  padding: 34px 24px;\n  top: 5%;\n  height: calc(100vh - 10%);\n  display: flex;\n  flex-direction: column;\n  background: rgb(255, 255, 255);\n  border-radius: 1px;\n  border: 1px solid rgb(233, 233, 233);\n  box-shadow: 0px 10px 30px -24px rgba(75, 78, 83, 0.4);\n}\n\n.footer {\n  margin-top: 24px;\n  margin-bottom: 8px;\n}\n\n.buttons {\n  display: flex;\n}\n\n.agreementTerm {\n  border: 1px solid var(--cps-color-gray4);\n  border-radius: 5px;\n  overflow-y: auto;\n  flex: 1;\n  padding: 0px 16px;\n  min-height: 0;\n}\n\n.agreementTerm h2 {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `legal-agreements-modal-styles__header--nSLqm`,
	"body": `legal-agreements-modal-styles__body--EIOCm`,
	"policyWrapper": `legal-agreements-modal-styles__policyWrapper--x7pYZ`,
	"modal": `legal-agreements-modal-styles__modal--_Mbtd`,
	"card": `legal-agreements-modal-styles__card--XLK4B`,
	"footer": `legal-agreements-modal-styles__footer--Ek7tS`,
	"buttons": `legal-agreements-modal-styles__buttons--cipDM`,
	"agreementTerm": `legal-agreements-modal-styles__agreementTerm--qVpNX`
};
export default ___CSS_LOADER_EXPORT___;
