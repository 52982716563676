import { get } from "lodash";
import { useState, useEffect } from "react";
import { handleError } from "src/handle-error.helper";

export function useSofeService(serviceName) {
  const [service, setService] = useState();
  useEffect(() => {
    SystemJS.import(`${serviceName}!sofe`).then((s) => setService(s));
  }, []);

  return service;
}

export function useAgreeToVersions(user, onCompleted) {
  const [saving, setSaving] = useState(false);
  const [pendingSave, setPendingSave] = useState();
  const fetcher = useSofeService("fetcher");

  useEffect(() => {
    if (user.id && fetcher && pendingSave) {
      const existingAgreements = get(user, "legal_agreements", []);
      setSaving(true);
      const req = fetcher
        .fetchAsObservable(`/wg/users/${user.id}`, {
          method: "PATCH",
          body: {
            users: {
              legal_agreements: [...existingAgreements, ...pendingSave],
            },
          },
        })
        .subscribe(
          (r) => {
            onCompleted();
            setSaving(false);
            setPendingSave();
          },
          (err) => {
            setSaving(false);
            handleError(err);
          }
        );

      return () => {
        req.unsubscribe();
      };
    }
  }, [user.id, pendingSave, fetcher]);

  return [saving, setPendingSave];
}
