import { hasAccess } from "./permission.helpers";
import UseWithUserAndTenant from "./use-with-user-and-tenant";

export function useHasAccess(permissions = [], requireAll) {
  if (!permissions || !permissions.length) {
    console.warn("No permissions were passed to `useHasAccess`");
  }
  const [user] = UseWithUserAndTenant();

  return hasAccess(user)(permissions, requireAll);
}
