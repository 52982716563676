export function decodeCookie(cookie) {
  try {
    const split = cookie.split("=")[1];
    const decoded = window.atob(split);
    return JSON.parse(decoded);
  } catch (e) {
    return {};
  }
}

export function findBestAuthInfoCookie(cookieArray = []) {
  // TODO: check if this is contributing to the recent token thrashing
  if (cookieArray.length === 0) {
    return {};
  } else if (cookieArray.length === 1) {
    return decodeCookie(cookieArray[0]);
  } else {
    const validCookieWithLatestExpiration = cookieArray.reduce(
      (accumulator, currentValue) => {
        const cookie = decodeCookie(currentValue);
        if (cookie && cookie.userId) {
          if (accumulator.userId === undefined) {
            return cookie;
          } else if (cookie.expires_at >= accumulator.expires_at) {
            return cookie;
          }
        }
        return accumulator;
      },
      {}
    );
    return validCookieWithLatestExpiration;
  }
}

export function getBestAuthInfoCookie() {
  const rawCookies = document.cookie
    .split(";")
    .filter((cookie) => cookie.trim().indexOf("authInfo") === 0);
  return findBestAuthInfoCookie(rawCookies);
}
