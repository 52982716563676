import { BehaviorSubject } from "rxjs";
import { handleError } from "src/handle-error.helper";
import auth from "./cp-client-auth.js";
import { skipWhile } from "rxjs/operators";

let userFetchedOnce = !!window.loggedInUser;
let tenantFetchedOnce = !!window.tenant;
const userSubjectRxjs = new BehaviorSubject(window.loggedInUser);
const tenantSubjectRxjs = new BehaviorSubject(window.tenant);

if (window.loggedInUser && isNotOnLogoutPage()) {
  setUser(window.loggedInUser);
}

if (window.tenant && isNotOnLogoutPage()) {
  setTenant(window.tenant);
}

function isNotOnLogoutPage() {
  return (
    window.location.hash.indexOf("#/logout") === -1 &&
    !window.location.href.includes("/m/logout")
  );
}

export function getLoggedInUserAsObservable() {
  fetchUserIfNotFetched();
  return userSubjectRxjs.asObservable().pipe(skipWhile((user) => !user));
}

function fetchUserIfNotFetched() {
  if (!userFetchedOnce) {
    refetchLoggedInUser(true).catch((err) => {
      if (err && err.status === 401) {
        // ignore
      } else {
        handleError(err);
      }
    });
  }
  userFetchedOnce = true;
}

export function refetchLoggedInUser(passThrough401) {
  return SystemJS.import("fetcher!sofe")
    .then((fetcherModule) =>
      Promise.all([
        fetcherModule
          .fetchAsObservable("/users/0", { passThrough401 })
          .toPromise(),
      ])
    )
    .then(([{ users }]) => ({
      ...users,
    }))
    .then(setUser)
    .catch((err) => {
      if (err && err.status === 401) {
        // ignore
      } else {
        handleError(err);
      }
    });
}

export function updateLoggedInUserObservable(newUser) {
  setUser(newUser);
}

function setUser(user) {
  userSubjectRxjs.next(user);
  window.loggedInUser = user;
  if (!window.loggedInUserTime) {
    window.loggedInUserTime =
      new Date().getTime() - window.appLoaderInitialTime;
  }
  return user;
}

export function getTenantAsObservable() {
  fetchTenantIfNotFetched();
  return tenantSubjectRxjs.asObservable().pipe(skipWhile((tenant) => !tenant));
}

function fetchTenantIfNotFetched() {
  if (!tenantFetchedOnce) {
    refetchTenant(true).catch((err) => {
      if (err && err.status === 401) {
        // ignore
      } else {
        handleError(err);
      }
    });
  }
  tenantFetchedOnce = true;
}

export function getTenant() {
  fetchTenantIfNotFetched();
  return tenantSubjectRxjs.getValue();
}

export function getLoggedInUser() {
  fetchUserIfNotFetched();
  return userSubjectRxjs.getValue();
}

export function refetchTenant(passThrough401) {
  return SystemJS.import("fetcher!sofe")
    .then((fetcherModule) =>
      fetcherModule
        .fetchAsObservable("/tenants/0", { passThrough401 })
        .toPromise()
    )
    .then((data) => data.tenants)
    .then(setTenant);
}

export function updateTenantObservable(newTenant) {
  setTenant(newTenant);
}

function setTenant(tenant) {
  tenantSubjectRxjs.next(tenant);
  window.tenant = tenant;
  if (!window.tenantTime) {
    window.tenantTime = new Date().getTime() - window.appLoaderInitialTime;
  }
  return tenant;
}

export function checkLoginStatus() {
  if (window.preAuthUrlActive) {
    const params = new URL(window.location).searchParams;
    const preAuthToken = params.get("token");
    const preAuthSessionId = params.get("session_id");
    if (preAuthToken || preAuthSessionId) {
      return Promise.all([refetchLoggedInUser(true), refetchTenant(true)])
        .then(([user, tenant]) => {
          return { isLoggedIn: true };
        })
        .catch((err) => ({ isLoggedIn: false }));
    } else {
      return Promise.resolve({ isLoggedIn: false });
    }
  }

  return Promise.all([SystemJS.import("fetcher!sofe")])
    .then(([fetcherModule]) => {
      return fetcherModule.default(`/wg/token`, { passThrough401: true });
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(
          Error(
            `Could not check if user is logged in -- GET /token responded with HTTP status '${response.status}'`
          )
        );
      }
    })
    .then((data) => {
      if (data.identitySeconds < 0 && data.refreshSeconds < 0) {
        // Neither identity nor refresh token exists/is valid.
        return { isLoggedIn: false };
      } else if (data.identitySeconds < 0) {
        // Our identity token got overridden (maybe logging into different env on same domain), but we can get a new one with refresh token.
        // TODO: check if this is contributing to the token thrashing
        return new Promise((resolve, reject) => {
          auth
            .refreshAuthToken({ preventLoginRedirect: true })
            .then(() => {
              // We should update the user and tenant observables (needed for when the app is initializing and couldn't get the user and tenant because identity token expired)
              return Promise.all([refetchLoggedInUser(), refetchTenant()]).then(
                () => {
                  resolve({ isLoggedIn: true });
                }
              );
            })
            .catch((err) => resolve({ isLoggedIn: false }));
        });
      } else if (data.refreshSeconds < 0) {
        // Somehow they have a valid identity token but have lost their refresh token. They can make api calls until their identity token runs out.
        return { isLoggedIn: true };
      } else {
        // Both identity seconds and refresh seconds are greater than zero.
        return { isLoggedIn: true };
      }
    });
}
