// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.practice-management-freemium-welcome-back-modal-styles__dialog--hxsXk {
  width: 53rem;
}

.practice-management-freemium-welcome-back-modal-styles__header--boxUF {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.4rem;
}

.practice-management-freemium-welcome-back-modal-styles__body--dtzq_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.4rem;
  padding-top: 0;
  font-size: 1.6rem;
  text-align: center;
}

.practice-management-freemium-welcome-back-modal-styles__icon--T3kYJ {
  width: 9.6rem;
  height: 9.6rem;
  margin-bottom: 1.6rem;
}

.practice-management-freemium-welcome-back-modal-styles__updatedText--PuVyd {
  margin-top: 2.4rem;
  font-style: italic;
}

.practice-management-freemium-welcome-back-modal-styles__upgradeText--SEFSk {
  margin: 2.4rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/practice-management-freemium/practice-management-freemium-welcome-back-modal.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dialog {\n  width: 53rem;\n}\n\n.header {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding: 1.4rem;\n}\n\n.body {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 2.4rem;\n  padding-top: 0;\n  font-size: 1.6rem;\n  text-align: center;\n}\n\n.icon {\n  width: 9.6rem;\n  height: 9.6rem;\n  margin-bottom: 1.6rem;\n}\n\n.updatedText {\n  margin-top: 2.4rem;\n  font-style: italic;\n}\n\n.upgradeText {\n  margin: 2.4rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `practice-management-freemium-welcome-back-modal-styles__dialog--hxsXk`,
	"header": `practice-management-freemium-welcome-back-modal-styles__header--boxUF`,
	"body": `practice-management-freemium-welcome-back-modal-styles__body--dtzq_`,
	"icon": `practice-management-freemium-welcome-back-modal-styles__icon--T3kYJ`,
	"updatedText": `practice-management-freemium-welcome-back-modal-styles__updatedText--PuVyd`,
	"upgradeText": `practice-management-freemium-welcome-back-modal-styles__upgradeText--SEFSk`
};
export default ___CSS_LOADER_EXPORT___;
