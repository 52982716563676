import useWithUserAndTenant from "src/use-with-user-and-tenant";
import {
  isClientLimitModel,
  isPaidClientLimitModelTier,
  isFreeClientLimitModelTier,
} from "./client-limit-model.helper";

export function useIsClientLimitModel() {
  const [user, tenant] = useWithUserAndTenant();
  return tenant ? isClientLimitModel(tenant.client_limit) : null; // if tenant is null, return null
}

export function useClientLimitModelTier() {
  const [user, tenant] = useWithUserAndTenant();

  if (tenant) {
    if (isPaidClientLimitModelTier(tenant.client_limit)) {
      return "PAID";
    } else if (isFreeClientLimitModelTier(tenant.client_limit)) {
      return "FREE";
    } else {
      return "NONE";
    }
  } else {
    return null; // if tenant is null, return null
  }
}
