import React from "react";
import { createRoot } from "react-dom/client";
import PracticeManagementFreemiumWelcomeBackModal from "./practice-management-freemium-welcome-back-modal.component";
import { filter, first, delay } from "rxjs/operators";
import { getLoggedInUserAsObservable } from "src/user-tenant-data.js";
import Auth from "src/cp-client-auth.js";

export async function checkPracticeManagementFreemiumWelcomeBackModal() {
  return new Promise((resolve, reject) => {
    getLoggedInUserAsObservable()
      .pipe(
        filter((user) => !!user),
        first(),
        delay(2500)
      )
      .subscribe(
        () => {
          let container;
          if (
            Auth.getCookie().enforce_client_limit &&
            !sessionStorage.getItem(
              "hasClosedPracticeManagementFreemiumWelcomeBackModal"
            )
          ) {
            container = document.createElement("div");
            container.id =
              "practice-management-freemium-welcome-back-container";
            document.body.appendChild(container);
            const root = createRoot(container);
            function close() {
              if (container) {
                root?.unmount();
                container?.remove();
              }
            }
            root.render(
              <PracticeManagementFreemiumWelcomeBackModal close={close} />
            );
          }
        },
        (error) => reject(error),
        () => resolve()
      );
  });
}
